.overlay {
  position: fixed;
  top: 0;
  left: 0;

  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  background-color: #e7e0eb;
}

.applyPage {
   width: 100vw;
  height: 100%;
}

.closeApplyPage {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
  font-size: 2em;
}

.mobilePhoneNumber {

  font-size: 1vw;
  text-align: center;
  position: relative;

}

.span_p{
  margin-left: 1vw;
  margin-right: 1vw;
  
}



/****************************************************************/

.wrapper{
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  height: 100%;


}



.errorText {
  color: #80358c;
  font-weight: bold;
  font-size: 1vw;
  

}


.aboutMe {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  width: 50%;
}

.contactMe {
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: center;
}

.myPicture img {
  width: 20vw;
  height: auto;
  border-radius: 30px;
}

.myNameAndRole .MyName{
  color: #80358c;
  font-size: 2vw;
  font-weight: bold;
}

.MyRole {
  font-size: 1.7vw;
  margin-top: -2vh;
  font-weight: bold;
}

.myNameAndRole {
  margin-top: 2vh;
}

input {
  width: 40vw;
  height: 5vh;
  margin-top: 5%;
  background-color: white;

  border-radius: 10px;
  border-color: white;
}



.messageInput textarea {
  width: 40vw;
  height: 14vh;
  resize: none;
  border-radius: 10px;
  border-color: white;
  padding: 5px;
  margin-top: 5%;
}

.buttonSend button {
  width: 15vw;
  height: 5vh;
  background-color: white;
  color: #80358c;
 
  border-width: 0;
  border-radius: 15px;
  border-color: white;
  margin-top: 3vh;
  margin-bottom: 10vh;
  font-weight: bold;
  font-size: 1vw;
  transition: background-color 0.3s, transform 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonSend button:hover {
  background-color: #80358c;
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.datenschutzContainer {
  display: flex;
  justify-content: baseline;
  align-items: center;
  width: 60%;

  text-align: left;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 2%;
}

.checkbox {
  width: 2vh;
  margin-right: 2%;
  transform: translateY(1.8vh);
}

.datenschutzText {
  font-size: 2vh;
}



.nameInput input,
.emailInput input,
.messageInput textarea {
  font-size: 1vw;
}

@media only screen and (max-width: 1999px) {
  .MyName {
    color: #80358c;
    font-size: 2vw;
    font-weight: bold;
  }

  .MyRole {
    font-size: 2vw;
    margin-top: -2vh;
    font-weight: bold;
  }

  .myNameAndRole {
    margin-top: 2vh;
  }

  .nameInput input::placeholder,
  .emailInput input::placeholder,
  .messageInput textarea::placeholder {
    font-size: 1vw;
    color: #999;
  }

  .nameInput input,
  .emailInput input,
  .messageInput textarea {
    font-size: 1vw;
  }

  .checkbox {
    width: 1.5vh;
  }

  .datenschutzText {
    font-size: 1.5vh;
  }


}

@media only screen and (max-width: 1500px) {
  .mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10vh;
  }

  .errorText {
    color: #80358c;
    font-weight: bold;
    font-size: 2vw;
  }

  .aboutMe {
    display: none;
  }

  input {
    width: 60vw;
    height: 5vh;
    background-color: white;
    border-radius: 10px;
    border-color: white;
  }

  .nameInput input,
  .emailInput input,
  .messageInput textarea {
    font-size: 2vw;
  }

  .nameInput input::placeholder,
  .emailInput input::placeholder,
  .messageInput textarea::placeholder {
    font-size: 2vw;
    color: #999;
  }

  .messageInput textarea {
    width: 60vw;
    height: 14vh;
    resize: none;
    border-radius: 10px;
    border-color: white;
    padding: 5px;
  }

  .buttonSend button {
    height: auto;
    width: auto;
    background-color: white;
    border-width: 0;
    border-radius: 15px;
    border-color: white;

    font-weight: bold;
    font-size: 2vw;
    padding: 15px;
    transition: background-color 0.3s, transform 0.3s;
  }

  .text p {
    text-align: left;
    width: 100%;
    margin-left: auto;
    margin-top: 1em;
    width: auto;
    font-size: 2vw;

    line-height: 1.2;
  }

  .datenschutzContainer {
    display: flex;
    justify-content: flex-start;
    width: auto;
    text-align: left;
    align-items: flex-start;
    margin-bottom: 5%;
  }

  .checkbox {
    width: 2vw;
    margin-right: 2%;


  }

  .datenschutzText {
    font-size: 1.5vw;
    text-align: left;
  }
  .mobilePhoneNumber{
    margin-top: 15svh;
    font-size: 1.5vh;


  }
}

@media only screen and (max-width: 767px) {
  .mainContainer {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 10vh;
  }

  .aboutMe {
    display: none;
  }

  .buttonSend button {
    height: auto;
    width: auto;
    background-color: white;
    border-width: 0;
    border-radius: 15px;
    border-color: white;

    font-weight: bold;
    font-size: 2vw;
    padding: 15px;
    transition: background-color 0.3s, transform 0.3s;
  }

  .text p {
    text-align: left;
    width: 100%;
    margin-right: 20%;
    margin-top: 1em;
    width: auto;
    font-size: 2vw;

    line-height: 1.2;
  }

  .datenschutzContainer {
    display: flex;
    justify-content: flex-start;
    width: auto;
    text-align: left;
    align-items: flex-start;
    margin-bottom: 5%;
  }

  .checkbox {
    width: 1.5vh;
    margin-right: 2%;
    transform: translateY(1vh);
  }

  .datenschutzText {
    font-size: 2vh;
    text-align: left;
  }

  input {
    width: 60vw;
    height: 3vh;
    background-color: white;
    border-radius: 10px;
    border-color: rgba(168, 168, 168, 0.349);
  }
}

@media only screen and (max-width: 599px) {
  .mainContainer {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 10vh;
  }

  .aboutMe {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-self: flex-start;
    align-items: center;

    text-align: left;
    margin-left: 3vw;
    display: none;
  }

  .contactMe {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .myPicture img {
    width: 30vw;
    height: auto;
    margin-top: 5vh;
  }

  .MyName {
    color: #80358c;
    font-size: 3vw;
    font-weight: bold;
  }

  .MyRole {
    font-size: 2.6vw;
    margin-top: -2vh;
    font-weight: bold;
  }

  .myNameAndRole {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-self: flex-start;
    align-items: center;
    margin-top: 2vh;
  }

  input {
    width: 60vw;
    height: 3vh;
    background-color: white;
    border-radius: 10px;
    border-color: rgba(168, 168, 168, 0.349);
  }

  .messageInput textarea {
    width: 60vw;
    height: 14vh;
    resize: none;
    border-radius: 10px;
    border-color: rgba(168, 168, 168, 0.349);
    padding: 5px;
  }
  .buttonSend button {
    width: 50vw;
    height: auto;
    background-color: white;
    border-width: 0;
    border-radius: 15px;
    border-color: white;
    margin-top: 5vh;
    margin-bottom: 20%;
    font-weight: bold;
    font-size: 2.6vw;
    transition: background-color 0.3s, transform 0.3s;
  }

  .buttonSend button:hover {
    background-color: #80358c;
    transform: scale(1.1);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .datenschutzContainer {
    display: flex;
    justify-content: flex-start;
    width: auto;
    text-align: left;
    align-items: flex-start;
    margin-bottom: 5%;
  }

  .checkbox {
    width: 1.5vh;
    margin-right: 2%;
    transform: translateY(1vh);
  }

  .datenschutzText {
    font-size: 2vh;
    text-align: left;
  }

}

@media only screen and (max-width: 479px) {
  .errorText {
    font-size: 3vw;
  }

  .mainContainer {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 10vh;
  }

  .aboutMe {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-self: flex-start;
    align-items: center;

    text-align: left;
    margin-left: 3vw;
    display: none;
  }

  .contactMe {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .myPicture img {
    width: 30vw;
    height: auto;
    margin-top: 5vh;
  }

  .MyName {
    color: #80358c;
    font-size: 3vw;
    font-weight: bold;
  }

  .MyRole {
    font-size: 2.6vw;
    margin-top: -2vh;
    font-weight: bold;
  }

  .myNameAndRole {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-self: flex-start;
    align-items: center;
    margin-top: 2vh;
  }

  input {
    width: 60vw;
    height: 3vh;

    background-color: white;
    border-radius: 10px;
    border-color: white;
  }

  .messageInput textarea {
    width: 60vw;
    height: 14vh;
    resize: none;
    border-radius: 10px;
    border-color: white;
    padding: 5px;
  }

  .buttonSend button {
    width: 50vw;
    height: auto;
    background-color: white;
    border-width: 0;
    border-radius: 15px;
    border-color: white;
    margin-top: 5vh;
    margin-bottom: 20%;
    font-weight: bold;
    font-size: 2.6vw;
    transition: background-color 0.3s, transform 0.3s;
  }

  .buttonSend button:hover {
    background-color: #80358c;
    transform: scale(1.1);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .datenschutzContainer {
    display: flex;
    justify-content: flex-start;
    width: auto;
    text-align: left;
    align-items: flex-start;
    margin-bottom: 5%;
  }

  .checkbox {
    width: 5vw;
    margin-right: 3%;
    transform: translateY(1vh);
  }

  .datenschutzText {
    font-size: 4vw;
    text-align: left;
  }
}
