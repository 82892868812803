.secondPage {
  background-color: #e7e0eb;
  position: relative;
  width: 100vw;
  height: 100%;
  overflow: hidden;
}

.pictures {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;

}

.pictureContainer {
  position: relative;
  text-align: center;
}

.pictures img {
  width: 30%;
  height: auto;
  padding: 1em;
  transition: transform 0.3s;
  
}

.imageText,
.imageBackgroundText {
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  text-align: center;
  font-weight: bold;
  transition: color 0.3s;
  width: 30%;
}

.imageText {
  color: white;
  font-size: 3vw;
}

.imageBackgroundText {
  color: transparent;
  font-size: 2vh;
  width: 30%;
  padding: 1em;
  text-align: center;

}




.pictureContainer:hover img {
  transform: scale(1.1);
}

.pictureContainer:hover .imageText {
  color: transparent;
}

.pictureContainer:hover .imageBackgroundText {
  color: white;
  background-color: rgba(68, 68, 68, 0.8);
}

@media only screen and (max-width: 1680px){

  .pictures img {
    width: 50%;
    height: auto;
    transition: transform 0.3s;
  }
  .imageText {
    color: white;
    font-size: 4vw;
  }

  .imageBackgroundText {

    font-size: 1.5vw;
    width: auto;
    max-width: 30%;
  }
}


@media only screen and (max-width: 768px) {

  .secondPage {
    height: 100%;
  }
  .pictures img {
    width: 60%;
    height: auto;
    transition: transform 0.3s;
  }
  .imageText {
    color: white;
    font-size: 5vw;
  }

  .imageBackgroundText {

    font-size: 2vw;
    width: auto;
    max-width: 50%;
  }
}


@media only screen and (max-width: 480px) {

  .pictures img {
    width: 80%;
    height: auto;
    transition: transform 0.3s;
  }
  .imageText {
    color: white;
    font-size: 6vw;
 
  }

  .imageBackgroundText {

    font-size: 10px;
    width: 60vw;
    
  }
}









@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.AnimationTitle,
.AnimationPictures {
  animation-name: slideInFromLeft;
  animation-duration: 2s;
  animation-timing-function: ease-out;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
}

.AnimationTitle,
.AnimationPictures {
  will-change: transform, opacity;
}
