.lastPage {
  background-color: #e4e4e4;
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

}

.kontaktInfo {

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 4vh;
}

.kontaktInfo img {
  width: 100%;
  margin-top: 4vh;
}

.copyRight {
  margin-top: 6vh;
  text-align: center;
  font-size: small;
}

.impressumWindow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  flex-direction: column;
}

.impressumWindow p {
  color: white;
  text-align: center;
}

.impressumWindow_haftungshinweis p {
  text-align: center;
}

.closeButton {
  cursor: pointer;
  background-color: #333;
  color: white;
  padding: 10px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
}

.closeButton:hover {
  cursor: pointer;
  background-color: #80358c;
  color: white;
  border: none;
  border-radius: 5px;
}

.impressum {
  color: #80358c;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
  font-size: 2vh;
  margin-top: 3%;
  margin-left: 2%;
}
