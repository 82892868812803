.FifthPage {
  background-color: #e7e0eb;
  height: 100%;
  width: 100vw;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.errorText {
  color: #80358c;
  font-weight: bold;
  font-size: 1vw;
}

.mainContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 10vh;
  margin-bottom: 10vw;
}

.aboutMe {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  width: 50%;
}

.myPhoneBox {
  text-align: center;
  font-weight: bold;
  margin-top: 10vw;
  margin-bottom: 20vw;
  width: 100%;
  display: flex; 
  flex-direction: column; 
  align-items: center; 
}

.underline {
  width: 80%; 
  border-bottom: 3px solid black;
  margin: 20px;
}
.contactMe {
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: center;
}

.myPicture img {
  width: 20vw;
  height: auto;
}

.MyName {
  color: #80358c;
  font-size: 2vw;
  font-weight: bold;
}

.MyRole {
  font-size: 1.7vw;
  margin-top: -2vh;
  font-weight: bold;
}

.myNameAndRole {
  margin-top: 2vh;
}

input {
  width: 40vw;
  height: 5vh;
  margin-top: 5%;
  background-color: white;

  border-radius: 10px;
  border-color: white;
}

.messageInput textarea {
  width: 40vw;
  height: 14vh;
  resize: none;
  border-radius: 10px;
  border-color: white;
  padding: 5px;
  margin-top: 5%;
}

.buttonSend button {
  width: 10vw;
  height: 5vh;
  background-color: white;
  color: #80358c;
  border-width: 0;
  border-radius: 15px;
  border-color: white;
  margin-top: 4vh;
  margin-bottom: 3vh;
  font-weight: bold;
  font-size: 1vw;
  transition: background-color 0.3s, transform 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonSend button:hover {
  background-color: #80358c;
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.datenschutzContainer {
  display: flex;
  justify-content: baseline;
  align-items: center;
  width: 60%;

  text-align: left;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 2%;
}

.checkbox {
  margin-right: 2%;
  transform: translateY(2vh);
}

.datenschutzText {
  font-size: 2vh;
}

.nameInput input::placeholder,
.emailInput input::placeholder,
.messageInput textarea::placeholder {
  font-size: 1vw;
  color: #999;
}

.nameInput input,
.emailInput input,
.messageInput textarea {
  font-size: 1vw;
}

.phoneInfoContainer {
  text-align: left;
}

@media only screen and (max-width: 1999px) {
  .MyName {
    color: #80358c;
    font-size: 2vw;
    font-weight: bold;
  }

  .MyRole {
    font-size: 2vw;
    margin-top: -2vh;
    font-weight: bold;
  }

  .myNameAndRole {
    margin-top: 2vh;
  }

  .nameInput input::placeholder,
  .emailInput input::placeholder,
  .messageInput textarea::placeholder {
    font-size: 1vw;
    color: #999;
  }

  .nameInput input,
  .emailInput input,
  .messageInput textarea {
    font-size: 1vw;
  }

  .checkbox {
    width: 1.5vh;
  }

  .datenschutzText {
    font-size: 1.5vh;
  }
}

@media only screen and (max-width: 1500px) {
  .mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5vh;
  }

  .errorText {
    color: #80358c;
    font-weight: bold;
    font-size: 2vw;
  }

  .aboutMe {
    display: none;
  }

  input {
    width: 60vw;
    height: 5vh;
    background-color: white;
    border-radius: 10px;
    border-color: white;
  }

  .nameInput input,
  .emailInput input,
  .messageInput textarea {
    font-size: 1.5vw;
  }

  .nameInput input::placeholder,
  .emailInput input::placeholder,
  .messageInput textarea::placeholder {
    font-size: 1.5vw;
    color: #999;
  }

  .messageInput textarea {
    width: 60vw;
    height: 14vh;
    resize: none;
    border-radius: 10px;
    border-color: white;
    padding: 5px;
  }

  .buttonSend button {
    height: auto;
    width: auto;
    background-color: white;
    border-width: 0;
    border-radius: 15px;
    border-color: white;

    font-weight: bold;
    font-size: 1.5vw;
    padding: 15px;
    transition: background-color 0.3s, transform 0.3s;
  }

  .text p {
    text-align: left;
    width: 100%;
    margin-left: auto;
    margin-top: 1em;
    width: auto;
    font-size: 2vw;

    line-height: 1.2;
  }

  .datenschutzContainer {
    display: flex;
    justify-content: flex-start;
    width: auto;
    text-align: left;
    align-items: flex-start;
    margin-bottom: 5%;
  }

  .checkbox {
    width: 1.5vw;
    margin-right: 2%;
  }

  .datenschutzText {
    font-size: 1.5vw;
    text-align: left;
  }
}

@media only screen and (max-width: 767px) {
  .mainContainer {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 5vh;
  }

  .aboutMe {
    display: none;
  }

  .buttonSend button {
    height: auto;
    width: auto;
    background-color: white;
    border-width: 0;
    border-radius: 15px;
    border-color: white;

    font-weight: bold;
    font-size: 2vw;
    padding: 15px;
    transition: background-color 0.3s, transform 0.3s;
  }

  .text p {
    text-align: left;
    width: 100%;
    margin-right: 20%;
    margin-top: 1em;
    width: auto;
    font-size: 2vw;

    line-height: 1.2;
  }

  .datenschutzContainer {
    display: flex;
    justify-content: flex-start;
    width: auto;
    text-align: left;
    align-items: flex-start;
    margin-bottom: 5%;
  }

  .checkbox {
    width: 2vh;
    margin-right: 2%;
    transform: translateY(1vh);
  }

  .datenschutzText {
    font-size: 2vh;
    text-align: left;
  }

  input {
    width: 60vw;
    height: 3vh;
    background-color: white;
    border-radius: 10px;
    border-color: rgba(168, 168, 168, 0.349);
  }

  .nameInput input,
  .emailInput input,
  .messageInput textarea {
    font-size: 2vw;
  }

  .nameInput input::placeholder,
  .emailInput input::placeholder,
  .messageInput textarea::placeholder {
    font-size: 2vw;
    color: #999;
  }
}

@media only screen and (max-width: 599px) {
  .mainContainer {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 5vh;
  }

  .aboutMe {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-self: flex-start;
    align-items: center;

    text-align: left;
    margin-left: 3vw;
    display: none;
  }

  .contactMe {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .myPicture img {
    width: 30vw;
    height: auto;
    margin-top: 5vh;
  }

  .MyName {
    color: #80358c;
    font-size: 3vw;
    font-weight: bold;
  }

  .MyRole {
    font-size: 2.6vw;
    margin-top: -2vh;
    font-weight: bold;
  }

  .myNameAndRole {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-self: flex-start;
    align-items: center;
    margin-top: 2vh;
  }

  input {
    width: 60vw;
    height: 3vh;
    background-color: white;
    border-radius: 10px;
    border-color: rgba(168, 168, 168, 0.349);
  }

  .messageInput textarea {
    width: 60vw;
    height: 14vh;
    resize: none;
    border-radius: 10px;
    border-color: rgba(168, 168, 168, 0.349);
    padding: 5px;
  }
  .buttonSend button {
    width: 50vw;
    height: auto;
    background-color: white;
    border-width: 0;
    border-radius: 15px;
    border-color: white;
    margin-top: 5vh;
    margin-bottom: 20%;
    font-weight: bold;
    font-size: 2.6vw;
    transition: background-color 0.3s, transform 0.3s;
  }

  .buttonSend button:hover {
    background-color: #80358c;
    transform: scale(1.1);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .datenschutzContainer {
    display: flex;
    justify-content: flex-start;
    width: auto;
    text-align: left;
    align-items: flex-start;
    margin-bottom: 5%;
  }

  .checkbox {
    width: 2vh;
    margin-right: 2%;
    transform: translateY(1vh);
  }

  .datenschutzText {
    font-size: 2vh;
    text-align: left;
  }

  .nameInput input,
  .emailInput input,
  .messageInput textarea {
    font-size: 3vw;
  }

  .nameInput input::placeholder,
  .emailInput input::placeholder,
  .messageInput textarea::placeholder {
    font-size: 3vw;
    color: #999;
  }
}

@media only screen and (max-width: 479px) {
  .errorText {
    font-size: 3vw;
  }

  .mainContainer {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 5vh;
  }

  .aboutMe {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-self: flex-start;
    align-items: center;

    text-align: left;
    margin-left: 3vw;
    display: none;
  }

  .contactMe {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .myPicture img {
    width: 30vw;
    height: auto;
    margin-top: 5vh;
  }

  .MyName {
    color: #80358c;
    font-size: 3vw;
    font-weight: bold;
  }

  .MyRole {
    font-size: 2.6vw;
    margin-top: -2vh;
    font-weight: bold;
  }

  .myNameAndRole {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-self: flex-start;
    align-items: center;
    margin-top: 2vh;
  }

  input {
    width: 60vw;
    height: 3vh;

    background-color: white;
    border-radius: 10px;
    border-color: white;
  }

  .messageInput textarea {
    width: 60vw;
    height: 14vh;
    resize: none;
    border-radius: 10px;
    border-color: white;
    padding: 5px;
  }

  .buttonSend button {
    width: 50vw;
    height: auto;
    background-color: white;
    border-width: 0;
    border-radius: 15px;
    border-color: white;
    margin-top: 5vh;
    margin-bottom: 20%;
    font-weight: bold;
    font-size: 2.6vw;
    transition: background-color 0.3s, transform 0.3s;
  }

  .buttonSend button:hover {
    background-color: #80358c;
    transform: scale(1.1);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .datenschutzContainer {
    display: flex;
    justify-content: flex-start;
    width: auto;
    text-align: left;
    align-items: flex-start;
    margin-bottom: 5%;
  }

  .checkbox {
    width: 20px; /* Breite der Checkbox */
    height: 20px; /* Höhe der Checkbox */
    margin-right: 10px; /* Abstand zwischen Checkbox und Text */
    border-radius: 4px; /* Randeckenradius */
    cursor: pointer; /* Zeigerstil beim Überfahren */
    position: relative;
    top: -5px;
  }

  .datenschutzText {
    font-size: 4vw;
    text-align: left;
  }
}
